import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import React from "react"
import { withTrans } from "../withTrans"
import HighlightsComponent from "../components/HighlightsComponent"
import HeroComponent from "../components/HeroComponent"
import { Tab } from "@headlessui/react"
import CardsList from "../components/CardsList"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!) {
    gcms {
      libraryPages(locales: $locale) {
        title
        description
        sliderImages(locales: en) {
          url
          mimeType
        }
      }
      libraryHighlights(locales: $locale) {
        title
        description
        cardImage(locales: en) {
          url
        }
        slug
      }
    }
  }
`

class Library extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      libraryPageData: this.props.data.gcms.libraryPages[0],
      libraryHighlightsData: this.props.data.gcms.libraryHighlights,
    }
  }

  render() {
    const { t } = this.props
    return (
      <Layout>
        <Tab.Group
          defaultIndex={0}
        >
          <Tab.List className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:grid-cols-3 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
            <Tab className="col-span-3 lg:col-span-1 row-span-1  uppercase w-full lg:w-auto lg:order-3">
              <div className="text-center inline-block bg-white">
                <span className="text-2xl">&lt;</span>
                <span className="tracking-title-3 text-base ml-3 mr-2">
                  {t("navbar_title_library")}
                </span>
                <span className="text-2xl">&gt;</span>
              </div>
            </Tab>

            <Tab
              className={({ selected }) =>
                selected
                  ? " font-futurastd_bold lg:order-first"
                  : "lg:order-first"
              }
            >
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                <Link to="/services">{t("navbar_title_services")}</Link>
              </div>
            </Tab>

            <div className="lg:hidden separator inline-block align-middle mx-1"></div>

            <Tab
              className={({ selected }) =>
                selected
                  ? " font-futurastd_bold col-span-1 lg:order-3"
                  : "col-span-1 lg:order-3"
              }
            >
              <div className="bg-white lg:bg-title-double-slash lg:bg-left lg:bg-no-repeat lg:pl-8 text-label-fs-10 uppercase tracking-title-2 lg:inline-block leading-7 text-center">
                <div className="">{t("label_library_highlights")}</div>
              </div>
            </Tab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel className="">
              <HeroComponent
                sliderItems={this.state.libraryPageData.sliderImages}
                pageData={this.state.libraryPageData}
              />

              {/* Highlights Section */}
              <HighlightsComponent
                title={t("title_library_highlights")}
                highlights={this.state.libraryHighlightsData}
              />
            </Tab.Panel>
            <Tab.Panel className="lg:order-1"></Tab.Panel>
            <Tab.Panel>
              <CardsList items={this.state.libraryHighlightsData} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </Layout>
    )
  }
}

export default withTrans(Library)
